import React from "react";
import Style from "./style";


function Services() {

    return(
<div>
    {/*<!-- services heading column -->*/}
    <div className="col-sm-12 p-0">
        <div className=" large-text-div">
            <h1 className="large-text-title">OUR SERVICES</h1>

        </div>



    </div>

    {/*<!-- services list section -->*/}
    <div className="container">
        <div className="services-data">
            <div className="col-sm-6">

                <div  className="servives-img" data-aos="fade-right"
                     data-aos-duration="800">
                    <img src="/images/post-img-1.webp" alt=""/>
                </div>

            </div>
            <div className="col-sm-6">
                <div className="Services-detail"  data-aos="fade-left">
                    <h3 className="services-small-heading">
                        Web Development
                    </h3>
                    <p className="services-detail-text">Redsqware provides custom web design and development for some
                        of the world's best-known brands. Our nationally award-winning specialists deliver
                        solutions you can rely on for business-critical operations. We've helped to create valuable
                        properties and products that are used, loved, and shared by millions of users.</p>
                    <button className="btn red-btn">Read More</button>
                </div>
            </div>
        </div>


        <div className="services-data">
            <div className="col-sm-6">
                <div className="Services-detail"  data-aos="fade-right">
                    <h3 className="services-small-heading">
                        Business Applications
                    </h3>
                    <p className="services-detail-text"> Today's web technology is more open than ever before, putting
                        custom web applications within the reach of any enterprise. Redsqware
                        has extensive expertise in designing and developing po
                        werful, stable, and cost-effective web applications for
                        demanding use cases – such as engineering, healthcare, and financial services.

                    </p>
                    <button className="btn red-btn">Read More</button>
                </div>
            </div>
            <div className="col-sm-6">
                <div className="servives-img" data-aos="fade-left"
                     data-aos-duration="800">
                    <img src="/images/post-img-2.webp" className=" img-responsive center-block" alt="" />
                </div>
            </div>

        </div>

        <div className="services-data">
            <div className="col-sm-6">
                <div className="servives-img " data-aos="fade-right"
                     data-aos-duration="800">
                    <img src="/images/post-img-3.webp" className=" img-responsive center-block" alt="" />
                </div>
            </div>
            <div className="col-sm-6">
                <div className="Services-detail"  data-aos="fade-left">
                    <h3 className="services-small-heading">
                        Mobile
                    </h3>
                    <p className="services-detail-text">Business, consumer, and beyond.
                        Whether you need native, a hybrid app, or are pursuing PWA,
                        Redsqware delivers. We can help design, spec and build a solution t
                        hat precisely fits your budget and requirements.
                    </p>
                    <button className="btn red-btn">Read More</button>
                </div>
            </div>
        </div>

        <div className="services-data">
            <div className="col-sm-6">
                <div className="Services-detail"  data-aos="fade-right">
                    <h3 className="services-small-heading">
                        Optimization & QA
                    </h3>
                    <p className="services-detail-text">You want deep structural, technical, and a
                        nalytics expertise. Razor-sharp quality assurance that puts you ahe
                        ad of the curve. Real business intelligence that matters to you. N
                        ot a one-size-fits-all solution.

                    </p>
                    <button className="btn red-btn">Read More</button>
                </div>
            </div>
            <div className="col-sm-6">
                <div className="servives-img" data-aos="fade-left"
                     data-aos-duration="800">
                    <img src="/images/post-img-4.webp" className=" img-responsive center-block" alt=""  />
                </div>
            </div>

        </div>
    </div>
<Style/>
</div>
    )
}
export default Services