import React from "react";
import Style from "./style"
import { Carousel } from "react-bootstrap";
function SelectedWorks() {
    return(
        <section className="selected-Works-Section">
            <div className="container-fluid">
                <div className="col-md-12 slected-padding-col">
                    <h2 className=".header-main-text selected-heading">
                        SELECTED WORKS
                    </h2>
                    <hr className="user-slider-line" />
                    <p className="selected-p">orem ipsum dolor sit amet, consec
                        tetur adipiscing elit.
                        Vivamus mollis, elit et laoreet v
                        olutpat, purus massa placerat risus</p>
                </div>
            </div>
            <div className="col-md-12 works-select">
                <ul>
                    <li className="active">ALL</li>
                    <li>WEB</li>
                    <li>MOBILE</li>
                    <li>BRANDING</li>
                    <li>DESIGN</li>
                    <li>SEO</li>
                </ul>
            </div>


            <div className="container-fluid">
                <Carousel id="box-slider">
                    <Carousel.Item>
                        <div className="col-sm-12">
                            <div className="col-sm-3 p-0">
                                <div className="work-image-div">
                                    <img src="/images/slider-img-1.webp" className="img-responsive" alt="" />
                                    <div className="box-image-overlay">
                                        <p>Mobile</p>
                                        <h3>Lorem ipsum</h3>
                                        <span><i className="fa fa-angle-right" aria-hidden="true"></i></span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-3 p-0">
                                <div className="work-image-div">
                                    <img src="/images/slider-img-2.webp" className="img-responsive" alt="" />
                                    <div className="box-image-overlay">
                                        <p>Mobile</p>
                                        <h3>Lorem ipsum</h3>
                                        <span><i className="fa fa-angle-right" aria-hidden="true"></i></span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-3 p-0">
                                <div className="work-image-div">
                                    <img src="/images/slider-img-3.webp" className="img-responsive" alt="" />
                                    <div className="box-image-overlay">
                                        <p>Mobile</p>
                                        <h3>Lorem ipsum</h3>
                                        <span><i className="fa fa-angle-right" aria-hidden="true"></i></span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-3 p-0">
                                <div className="work-image-div">
                                    <img src="/images/slider-img-4.webp" className="img-responsive" alt="" />
                                    <div className="box-image-overlay">
                                        <p>Mobile</p>
                                        <h3>Lorem ipsum</h3>
                                        <span><i className="fa fa-angle-right" aria-hidden="true"></i></span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-3 p-0">
                                <div className="work-image-div">
                                    <img src="/images/slider-img-5.webp" className="img-responsive" alt="" />
                                    <div className="box-image-overlay">
                                        <p>Mobile</p>
                                        <h3>Lorem ipsum</h3>
                                        <span><i className="fa fa-angle-right" aria-hidden="true"></i></span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-3 p-0">
                                <div className="work-image-div">
                                    <img src="/images/slider-img-6.webp" className="img-responsive" alt="" />
                                    <div className="box-image-overlay">
                                        <p>Mobile</p>
                                        <h3>Lorem ipsum</h3>
                                        <span><i className="fa fa-angle-right" aria-hidden="true"></i></span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-3 p-0">
                                <div className="work-image-div">
                                    <img src="/images/slider-img-7.webp" className="img-responsive" alt="" />
                                    <div className="box-image-overlay">
                                        <p>Mobile</p>
                                        <h3>Lorem ipsum</h3>
                                        <span><i className="fa fa-angle-right" aria-hidden="true"></i></span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-3 p-0">
                                <div className="work-image-div">
                                    <img src="/images/slider-img-8.webp" className="img-responsive" alt="" />
                                    <div className="box-image-overlay">
                                        <p>Mobile</p>
                                        <h3>Lorem ipsum</h3>
                                        <span><i className="fa fa-angle-right" aria-hidden="true"></i></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className="col-sm-12">
                            <div className="col-sm-3 p-0">
                                <div className="work-image-div">
                                    <img src="/images/slider-img-1.webp" className="img-responsive" alt="" />
                                    <div className="box-image-overlay">
                                        <p>Mobile</p>
                                        <h3>Lorem ipsum</h3>
                                        <span><i className="fa fa-angle-right" aria-hidden="true"></i></span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-3 p-0">
                                <div className="work-image-div">
                                    <img src="/images/slider-img-2.webp" className="img-responsive" alt="" />
                                    <div className="box-image-overlay">
                                        <p>Mobile</p>
                                        <h3>Lorem ipsum</h3>
                                        <span><i className="fa fa-angle-right" aria-hidden="true"></i></span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-3 p-0">
                                <div className="work-image-div">
                                    <img src="/images/slider-img-3.webp" className="img-responsive" alt="" />
                                    <div className="box-image-overlay">
                                        <p>Mobile</p>
                                        <h3>Lorem ipsum</h3>
                                        <span><i className="fa fa-angle-right" aria-hidden="true"></i></span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-3 p-0">
                                <div className="work-image-div">
                                    <img src="/images/slider-img-4.webp" className="img-responsive" alt="" />
                                    <div className="box-image-overlay">
                                        <p>Mobile</p>
                                        <h3>Lorem ipsum</h3>
                                        <span><i className="fa fa-angle-right" aria-hidden="true"></i></span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-3 p-0">
                                <div className="work-image-div">
                                    <img src="/images/slider-img-5.webp" className="img-responsive" alt="" />
                                    <div className="box-image-overlay">
                                        <p>Mobile</p>
                                        <h3>Lorem ipsum</h3>
                                        <span><i className="fa fa-angle-right" aria-hidden="true"></i></span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-3 p-0">
                                <div className="work-image-div">
                                    <img src="/images/slider-img-6.webp" className="img-responsive" alt="" />
                                    <div className="box-image-overlay">
                                        <p>Mobile</p>
                                        <h3>Lorem ipsum</h3>
                                        <span><i className="fa fa-angle-right" aria-hidden="true"></i></span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-3 p-0">
                                <div className="work-image-div">
                                    <img src="/images/slider-img-7.webp" className="img-responsive" alt="" />
                                    <div className="box-image-overlay">
                                        <p>Mobile</p>
                                        <h3>Lorem ipsum</h3>
                                        <span><i className="fa fa-angle-right" aria-hidden="true"></i></span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-3 p-0">
                                <div className="work-image-div">
                                    <img src="/images/slider-img-8.webp" className="img-responsive" alt="" />
                                    <div className="box-image-overlay">
                                        <p>Mobile</p>
                                        <h3>Lorem ipsum</h3>
                                        <span><i className="fa fa-angle-right" aria-hidden="true"></i></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Carousel.Item>

                </Carousel>

            </div>

        <Style/>
        </section>
    )
}
export default SelectedWorks