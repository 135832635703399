import React from "react";
import Style from "./style"
import { Carousel } from "react-bootstrap";
function HomeSlider() {
    return(
        <div>
            <Carousel id="header-background-slider">
                <Carousel.Item>
                <div className="header-slider-content-div">
                    <h2 className="header-main-text">Maximize Opportunity
                        Minimize Risk</h2>
                    <p>Your Proven Partner For Enterprise Development Outsourcing.</p>
                    <div className="slider-device ">
                        <img src="/images/slider-device.webp" className="img-responsive" alt=""/>
                    </div>
                </div>
            </Carousel.Item>
                <Carousel.Item>
                    <div className="header-slider-content-div">
                        <h2 className="header-main-text">Maximize Opportunity
                            Minimize Risk</h2>
                        <p>Your Proven Partner For Enterprise Development Outsourcing.</p>
                        <div className="slider-device ">
                            <img src="/images/slider-device.webp" className="img-responsive" alt=""/>
                        </div>
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    <div className="header-slider-content-div">
                        <h2 className="header-main-text">Maximize Opportunity
                            Minimize Risk</h2>
                        <p>Your Proven Partner For Enterprise Development Outsourcing.</p>
                        <div className="slider-device ">
                            <img src="/images/slider-device.webp" className="img-responsive" alt=""/>
                        </div>
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    <div className="header-slider-content-div">
                        <h2 className="header-main-text">Maximize Opportunity
                            Minimize Risk</h2>
                        <p>Your Proven Partner For Enterprise Development Outsourcing.</p>
                        <div className="slider-device ">
                            <img src="/images/slider-device.webp" className="img-responsive" alt=""/>
                        </div>
                    </div>
                </Carousel.Item>

            </Carousel>
            <div className="header-control-div">

            </div>
        <Style/></div>
)
}
export default HomeSlider;