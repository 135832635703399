import React from "react";
import HomeSlider from "../homeslider";
import Services from "../servicessection";
import Vision from "../visionsection";
import Review from "../reviewslider";
import Selected from "../selectedworks";
import Member from "../countersection";
import ContactUs from "../getintouch";
function homepagecomponent() {

    return(
        <div>
            <HomeSlider/>
            <Services/>
            <Vision heading={"OUR VISION"} />
            <Review/>
            <Selected/>
            <Member/>
            <ContactUs/>
        </div>
    )
}
export default homepagecomponent;