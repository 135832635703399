import React from "react";
import Style from "./style"
import { Carousel } from "react-bootstrap";
function Review() {
    return(
        <div>

            <section className="user-review-slider-section">
                <div id="user-review-slider" className="carousel carousel-fade slide" data-ride="carousel">
                    <Carousel
                        prevIcon={<img src="/images/left-arrow.webp" alt=""/>}
                        nextIcon={<img src="/images/right-arrow.webp" alt=""/>}
                    >
                        <Carousel.Item>
                            <div className="container user-slider-div media-left">
                                <div className="col-sm-3">
                                    <div className="user-img-div"></div>
                                    <hr className="user-slider-line" />
                                    <p className="user-name">JOHN DOE</p>
                                </div>
                                <div className="col-sm-8">
                                    <p className="user-revire-text">
                                        We have worked with Redsqware for several projects over the last 4 years with
                                        consistent positive results. They are knowledgeable, helpful, and deadline-driven.
                                        We have always appreciated the professional insight they bring to every project we
                                        work on together and truly feel like they are our partner. I would highly recommend
                                        them!
                                    </p>
                                </div>
                            </div>
                        </Carousel.Item>
                        <Carousel.Item>
                            <div className="container user-slider-div media-left">
                                <div className="col-sm-4">
                                    <div className="user-img-div"></div>
                                    <hr className="user-slider-line" />
                                    <p className="user-name">JOHN DOE</p>
                                </div>
                                <div className="col-sm-8">
                                    <p className="user-revire-text">
                                        We have worked with Redsqware for several projects over the last 4 years with
                                        consistent positive results. They are knowledgeable, helpful, and deadline-driven.
                                        We have always appreciated the professional insight they bring to every project we
                                        work on together and truly feel like they are our partner. I would highly recommend
                                        them!
                                    </p>
                                </div>
                            </div>
                        </Carousel.Item>
                        <Carousel.Item>
                            <div className="container user-slider-div media-left">
                                <div className="col-sm-4">
                                    <div className="user-img-div"></div>
                                    <hr className="user-slider-line" />
                                    <p className="user-name">JOHN DOE</p>
                                </div>
                                <div className="col-sm-8">
                                    <p className="user-revire-text">
                                        We have worked with Redsqware for several projects over the last 4 years with
                                        consistent positive results. They are knowledgeable, helpful, and deadline-driven.
                                        We have always appreciated the professional insight they bring to every project we
                                        work on together and truly feel like they are our partner. I would highly recommend
                                        them!
                                    </p>
                                </div>
                            </div>
                        </Carousel.Item>
                    </Carousel>
                </div>
            </section>
            <Style/>
        </div>
    )
}
export default Review;