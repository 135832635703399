import React from "react";
import Layout from "../components/layout";
import HomeComponent from "../components/homepegecomponent";
function index(){
return(
    <div>
        <Layout headimg ={"/images/top-logo.webp"} headclr={"white-list"} headbg={"transparent-bg"} callicon ={"/images/call-answer.webp"}>
    <HomeComponent/>
        </Layout>
    </div>
)
}
export default index;