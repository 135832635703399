import React from 'react'

export default () => (
    <style jsx="true">{`
.header-control-div:before {
    content: '';
    display: inline-block;
    width: 1px;
    height: 303px;
    background-color: #e3e3e3;
    position: inherit;
    left: -125px;
    transform: rotate(-130.5deg);
}
.header-control-div:after {
    content: '';
    display: inline-block;
    width: 1px;
    height: 303px;
    background-color: #e3e3e3;
    position: inherit;
    right: -105px;
    transform: rotate(130.5deg);
}
.header-control-div{
    position: absolute;
       top: 287px;
    left: 50%;
}
#header-background-slider {

    padding-top: 70px;
    background: linear-gradient(to right, rgba(182,46,64,1) 0%, rgba(221,33,58,1) 100%);

}
#header-background-slider .carousel-indicators li{
    background-color: rgba(255, 255, 255, 0.69);
    width: 12px;
    height: 12px;
}
#header-background-slider .carousel-indicators li.active{
    width: 14px;
    height: 14px;
    box-shadow: 1px -2px 13px rgba(255, 255, 255, 0.45);
}
 .carousel-indicators li:nth-child(1){

    border-radius: 10px;
    top: 396px;
    right: 136px;
}
 .carousel-indicators li:nth-child(2){
      left: -58px;
    border-radius: 10px;
    top: 313px;
    right: -3px;
}

.carousel-indicators li:nth-child(3){
    top: 313px;
    border-radius: 10px;
    left: 36px;

}

.carousel-indicators li:nth-child(4){
       top: 387px;
    /* position: absolute; */
    border-radius: 10px;
    right: 0px;
    left: 106px;
}
.header-slider-content-div{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

}
.header-slider-content-div .header-main-text {
    max-width: 800px;
    text-align: center;
    color: #e3e3e3;
}
.header-slider-content-div p {
    font-size: 15px;
    font-weight: 300;
    text-align: center;
    color: #e3e3e3;
    margin: 15px 0 0;
}
.header-slider-content-div img{
margin-top: 160px
}
.carousel-indicators{
    bottom: unset!important;
}
  #header-background-slider .carousel-control-prev ,  #header-background-slider  .carousel-control-next{
  display:none!important
  }
    `}</style>
)