import React from 'react'

export default () => (
    <style jsx="true">{`
.user-slider-rotate-text{
    transform: rotate(92deg);
    margin-top: 11px;
}
.user-slider-rotate-text ,#user-review-slider .fa{
color: black
}
.user-review-slider-section {
    min-height: 430px;
    background-image: url(/images/info-slider-bg.webp);
    background-size: cover;
    background-repeat: no-repeat;
    display: grid;
    align-items: center;
}
.user-slider-div .user-img-div {
    height: 65px;
    width: 65px;
    border: 2px solid #fff;
    border-radius: 100%;
    overflow: hidden;
    text-align: center;
    margin: 0 auto;
    background-image: url(/images/slider-man.webp);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.user-slider-div {
    display: flex;
    align-items: center;
        text-align: center;
}
.user-slider-line {
    width: 70px;
    background-color: #d72c42;
    height: 3px;
}
.user-name {
    color: #ff0032;
    font-weight: 300;
    letter-spacing: 4px;
    margin: 20px 0 0;
}
.user-revire-text {
    font-size: 22px;
    color: #333333;
    font-weight: 200;
    margin: 0;
    line-height: 35px;
    text-align: left;
    text-shadow: none;
    font-style: italic;
}
#user-review-slider .carousel-indicators{
display:none;
}
    `}</style>
)