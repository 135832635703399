import React from 'react'

export default () => (
    <style jsx="true">{`
.selected-Works-Section{
    background-color: #232529;
    min-height: 680px;
}
.selected-heading{
    text-align: center;
    font-size: 50px;
    color: #fff;
    font-weight: 200;
}
.selected-p{
    color: #595d66;
    font-weight: 500;
    max-width: 600px;
    text-align: center;
    margin: 0 auto 0 auto;
}
.slected-padding-col{
    padding: 90px 0px;
}
.works-select{
    text-align: center
}
.works-select ul::before{
    content: '';
    height: 4px;
    color: #393b3f;
    display: block;
    width: 100%;
    background-color: #393b3f;
    text-align: center;
    margin: 20px auto;
}
.works-select ul {
    margin: 0 auto;
    display: inline-block;
}
.works-select ul li{
    float: left
}
.works-select ul li  {
    color: white!important;
    padding: 0px 17px;
    font-size: 12px;
    cursor: pointer;
    list-style:none;
}
#box-slider{
    padding: 30px 0px;
}
#box-slider .col-sm-12{
display: flex;
flex-wrap: wrap
}
#box-slider img{
    width: 100%;
    height: 250px;
}
#box-slider .col-sm-3{
    // background-image: url(/images/slider-img-3.webp);
    background-size: 100% 105%;
    background-repeat: no-repeat;
    background-position: center;
    height: 35%;
}
#box-slider .col-sm-3:hover .box-image-overlay{
    display: block
}
.box-image-overlay {
    background-color: #d72c42;
    position: absolute;
    top: 0;
    width: 90%;
    height: 90%;
    margin: 12px 18px;
    cursor: pointer;
    display: none;
    text-align: center
}
.box-image-overlay p{
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600;
    margin: 55px 0 12px;
    color: #333;
}
.box-image-overlay h3{
    font-size: 21px;
    font-weight: 500;
    margin: 0 0 22px;
    color: #fff;
}
.box-image-overlay span{
    height: 40px;
    width: 40px;
    border: 2px solid #333;
    display: inline-block;
    border-radius: 100%;
    line-height: 36px;
    font-size: 18px;
    color: white;
    padding-top: 2px;
}
.box-slider-conrols{
    display: flex;
    justify-content: center
}
.box-slider-conrols span{
font-size: 40px;
color: white;
padding: 0px 20px
}
#box-slider .carousel-indicators{
display:none!important
}
#box-slider .carousel-control-prev{
    left: 40%!important;
    top:100%!important;
}
#box-slider .carousel-control-next{
    right: 40%!important;
    top:100%!important;
}
    `}</style>
)